
<template>
  <div class="elm">
    <!-- 头部 -->
    <el-menu
      :default-active="this.$router.path"
      router
      class="el-menu-demo"
      mode="horizontal"
      @select="menuSelect"
    >
      <span class="navigation-left">
        <img
          src="@/assets/image/home/heguishuju@2x.png"
          alt=""
          @click="tab(11)"
        />
      </span>

      <span class="header_navigation">
        <el-menu-item
          v-for="(item, index) in headerList"
          :key="index"
          @click="tab(index)"
          :class="type === index + 1 ? 'clickActive' : ''"
        >
          <img
            :src="item.img"
            alt=""
            :class="type === index + 1 ? 'clickActiveImg' : ''"
          />
          <span>{{ item.text }}</span>
          <div
            class="firstLine"
            :class="type === index + 1 ? 'clickActiveLine' : ''"
          ></div>
        </el-menu-item>
      </span>
      <span class="header_rigth">

        <el-popover placement="bottom" trigger="click">
          <div @click="dialogFormVisible = true">
            <img src="@/assets/image/icon/mima-2.svg" alt="" />
            <span>修改密码</span>
          </div>

          <div>
            <img src="@/assets/image/icon/touxiang.svg" alt="" />
            <span>头像设置</span>
          </div>
          <!-- <el-button slot="reference">click 激活</el-button> -->
          <el-menu-item class="username_image" slot="reference">
            <img
              src="@/assets/image/icon/c1f6f257a0556751a6ad0bba160a7d4.png"
              alt=""
            />
            {{ username }}</el-menu-item
          >
        </el-popover>

        <el-menu-item @click="loginout">
          <img src="@/assets/image/icon/tuichu.svg" alt="" />
        </el-menu-item>
      </span>
    </el-menu>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogFormVisible"
      @close="resetForm('ruleForm')"
    >
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        
        <el-form-item label="" prop="ypwd" >
          <span class="kuang">
            <span class="xing">∗</span>
          <span>原密码：</span>
          </span>
          <el-input
            type="password"
            v-model="ruleForm.ypwd"
            autocomplete="off"
            placeholder="请输入原始密码"
            
          ></el-input>
        </el-form-item>
        <el-form-item prop="pass">
           <span class="kuang">
             <span class="xing">∗</span>
          <span>新密码：</span>
           </span>
          <el-input
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
            placeholder="请输入6-15位数字加字母组合的密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="checkPass">
          <span class="kuang">
            <span class="xing">∗</span>
          <span>确认密码：</span>
          </span>
          <el-input
            type="password"
            v-model="ruleForm.checkPass"
            autocomplete="off"
            placeholder="请输入6-15位数字加字母组合的密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 主要内容 -->
    <el-main class="line">
      <router-view @child-event="getlist" :key="dateKey" v-if="isRouterAlive" />
    </el-main>
  </div>
</template>

<script>
import { eventBus } from "@/main";
import { loginoutApi } from "@/api/loginApi";
import { verifyPassword, changePwd } from "@/api/gpwd";
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
       var ypwd = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入原密码"));
      } else {
        // if (this.ruleForm.checkPass !== "") {
        //   this.$refs.ruleForm.validateField("checkPass");
        // }
        // callback();
        verifyPassword({
        password: String(value),
      }).then((res) => {
        if (res.data.code !== 200) {
          return callback(new Error("原密码不正确"));
        }
      callback();

      });
      }
    };
    var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,15}$/;
    var validatePass = (rule, value, callback) => {
      if (!reg.test(value)) {
        callback(new Error("请输入6-15位数字加字母组合的密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        ypwd: "", //原始密码
        pass: "", //新密码
        checkPass: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        ypwd: [
          { required: true, message: '原密码不能为空', trigger: 'blur' }
        ],
      },
      dateKey: "",
      currentIndex: "",
      activeIndex: "1",
      activeIndex2: "2",
      value: "",
      id: "",
      type: "",
      index: "",
      name: "",
      isRouterAlive: true,
      line: [],
      list: [],
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      formLabelWidth: "120px",
      permName: "",
      headerList: [
        {
          id: 1,
          img: require("@/assets/image/icon/yiwuqingdan.svg"),
          text: "义务清单",
        },
        {
          id: 2,
          img: require("@/assets/image/icon/guizhangzhidu.svg"),
          text: "规章制度",
        },
        {
          id: 3,
          img: require("@/assets/image/icon/chufaqingdan.svg"),
          text: "处罚清单",
        },
        {
          id: 4,
          img: require("@/assets/image/icon/heguizixun.svg"),
          text: "合规资讯",
        },
      ],
      username: "",
    };
  },
  mounted() {
    this.line = this.$store.state.line;
    this.username = this.$store.state.username;
  //  this.permName = this.line[0].permName;
    eventBus.$on("headerLine", (message) => {
      this.type = message;
    });

    eventBus.$on("changeLine", (item) => {
      this.value = item;
    });
    eventBus.$emit("homeLine", this.line);
    this.value = sessionStorage.getItem("line");
    // this.type = sessionStorage.getItem("index") + 1;
    //type = 11代表首页
    this.type =
      typeof sessionStorage.getItem("index") == "string"
        ? sessionStorage.getItem("index") - 0 + 1
        : 11;
  },
  methods: {
    getlist(data) {
      console.log("header", data);
    },
    //刷新
    menuSelect() {
      this.dateKey = this.id;
    },
    //下拉框
    change(item) {
      this.$store.commit("getId", item.value);
      this.id = this.$store.state.Id;
      sessionStorage.setItem("line", item.label);
      eventBus.$emit("changeLine", item.label);
      this.index = sessionStorage.getItem("index");
      this.tab(sessionStorage.getItem("index"));
      this.type = parseInt(this.index) + 1;
    },
    // 头部四个导航
    tab(index) {
      sessionStorage.setItem("index", index);
      this.id = this.$store.state.Id;
      this.type = index - 0 + 1;
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
      // 首页
      if (index == 11) {
        this.name = "home";
      }
      //义务清单
      else if (index == 0) {
        this.name = "dutyList";
      }
      //规章制度
      else if (index == 1) {
        this.name = "roles";
      }
      //处罚清单
      else if (index == 2) {
        this.name = "punish";
      } else if (index == 3) {
        this.name = "compliance";
      }
      if(localStorage.getItem("tenantId") == 11 || localStorage.getItem("tenantId") == 12){
        if (index != 2 && index != 11){
          this.name = "auth";
        }
      }
      this.$router.push({
        name: this.name,
        query: {
          type: this.type,
        },
      });
    },
    // 修改密码
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if(valid){
          changePwd({
            password: this.ruleForm.ypwd,
            newPassword: this.ruleForm.pass,
          }).then((res) => {
            if (res.data.code == 200) {
              this.$notify({
                title: '成功',
                message: '修改密码成功',
                type: 'success'
              });
              if(localStorage.getItem("tenantId") == 12){
                this.$router.push("/dj/login");
              }else{
                this.$router.push("/login");
              }
              window.sessionStorage.clear();
              window.localStorage.clear();
              this.$store.commit("getId", "");
            }else{
              this.$notify.error({
                title: '错误',
                message: res.data.msg
              });
            }
          });
        }
      })

    },
    //关闭重置表单
    resetForm(form) {
      this.ruleForm.ypwd = "";
      this.ruleForm.pass = "";
      this.ruleForm.checkPass = "";
      this.$refs[form].clearValidate();
    },
    // 退出登录
    loginout() {
      this.$confirm("确认要退出系统吗?", "退出提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        // center: true
      })
        .then(() => {
          loginoutApi().then((res) => {
            let tenantId = localStorage.getItem("tenantId");
            // 清空sessionStorage;
            window.sessionStorage.clear();
            window.localStorage.clear();

            if(tenantId == 12){
              this.$router.push("/dj/login");
            }else{
              this.$router.push("/login");
            }


            //清空vuex中存储的条线id
            this.$store.commit("getId", "");
            // this.$message({
            //   type: "success",
            //   message: "退出成功!",
            // });
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消退出",
          // });
        });
    },
  },
};
</script>
<style>
.line {
  overflow: hidden;
  overflow-y: scroll;
}
</style>
<style lang="scss" scoped>
@import "../../static/css/tanchu.css";
.el-main {
  background: #f3f5f7;
}
.elm {
  height: 100%;
}
.el-menu.el-menu--horizontal {
  width: 100%;
  display: flex;
  border: 0;
  /* height: 4.45%; */
  height: 48px;
  align-items: center;
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
  border-radius: 0px 0px 10px 10px;
  margin-bottom: 20px;
}
::v-deep ul > .el-select > .el-input {
  width: 93px;
  // min-width: 80px;
  // max-width: 95px;
}
::v-deep ul > .el-select > .el-input > input {
  border: 0;
  font-size: 16px;
}
::v-deep ul > .el-select > .el-input > ::placeholder {
  color: rgba(13, 26, 38, 1.5);
}
.navigation-left {
  line-height: 1;
}
.navigation-left img {
  margin: 2px 16px 0;
  // margin-left: 16px;
  // margin-right: 16px;
  // margin-top: 8px;
  display: inline-block;
  width: 110px;
  height: 22px;
  line-height: 1;
}
.el-icon-caret-bottom {
  font-size: 6px;
  color: #979797;
  margin: 0 5px;
}
input:disabled::-webkit-input-placeholder {
  -webkit-text-fill-color: #3b9df8;
}
.el-dropdown {
  color: rgba(13, 26, 38, 1);
  font-size: 16px;
}
.header_navigation {
  flex: 1;
  // height: 160px;
  justify-content: center; /* 子元素水平居中 */
  align-items: center; /* 子元素垂直居中 */
  display: flex;
  margin-left: -30px;
}
.header_navigation li {
  color: rgba(89, 89, 89, 1);
  overflow: hidden;
}
.header_navigation img {
  margin-right: 5px;
}
.firstLine {
  width: 24px;
  height: 2px;
  background-color: transparent;
  position: relative;
  left: 32px;
  top: -6px;
}
.clickActive {
  color: #1890ff !important;
  background-color: transparent;
}
.header_navigation .el-submenu__title:hover,
.el-menu-item:hover {
  background-color: transparent;
}
.header_navigation .el-submenu__title:hover,
.el-menu-item:hover span {
  color: #1890ff;
}
.header_navigation .el-submenu__title:hover,
.header_navigation .el-menu-item:hover img {
  filter: drop-shadow(#1890ff 50px 0);
  transform: translateX(-50px);
}
.clickActiveImg {
  filter: drop-shadow(#1890ff 50px 0);
  transform: translateX(-50px);
}
.clickActiveLine {
  background-color: #1890ff;
}
.el-menu-item:hover .firstLine {
  background-color: #1890ff;
}
.header_rigth {
  display: flex;
  width: 100px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: 27px;
  padding-left: 70px;
}
.header_rigth li {
  padding: 0 7px;
}
.header_rigth .username_image img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: white;
}
</style>